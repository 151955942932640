import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import type { Address } from '~/server/transformers/shop/addressTransformer';

export default function useDeleteAddressDialog() {
  const dialogStore = useDialogStore();
  type openOptions = Address;
  return {
    open: async (options: openOptions) => {
      return dialogStore.openDialog(DialogIdent.DELETE_ADDRESS, options);
    },
    getData: () => {
      return dialogStore.getDialogData<openOptions>(DialogIdent.DELETE_ADDRESS);
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data);
    },
  };
}
